import React, { useState } from 'react';

const HistoryList = ({ historyData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calculate total pages
  const totalPages = Math.ceil(historyData.length / itemsPerPage);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = historyData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Handle row click to open URI in new tab
  const handleRowClick = (uri) => {
    if (uri) {
      window.open(uri, '_blank');
    }
  };

  return (
    <div className="history-container">
      <table className="history-table">
        <thead>
          <tr>
            <th>Loan ID</th>
            <th>Note ID</th>
            <th>Note Name</th>
            <th>Balance</th>
            <th>Filing Date</th>
            <th>Closing Date</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((item, index) => (
              <tr 
                key={index} 
                onClick={() => handleRowClick(item.uri)} 
                style={{ cursor: 'pointer' }}
              >
                <td>{item.loanParentId}</td>
                <td>{item.id}</td>
                <td>{item.noteName}</td>
                <td>{item.balance}</td>
                <td>{new Date(item.report.filingDate).toLocaleDateString()}</td>
                <td>{new Date(item.report.closingDate).toLocaleDateString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No history available</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination">
        {/* Previous Page Button */}
        <button
          className="arrow-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &larr;
        </button>

        {/* Page Numbers */}
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))}

        {/* Next Page Button */}
        <button
          className="arrow-button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &rarr;
        </button>
      </div>
    </div>
  );
};

export default HistoryList;