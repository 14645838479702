import React, { useState } from "react";
import OrganizationForm from "./OrganizationForm";
import cn from 'classnames';
import OrganizationTable from "./OrganizationTable";
import { PageHeader } from "./PageHeader";
import Modal from "react-bootstrap/Modal";
import styles from "./AdminPanel.module.scss";

function AdminPanel({
  org,
  form,
  handleChange,
  handleSubmit,
  isUpdating,
  setIsUpdating,
  setForm,
  handleDeleteClick,
  logout,
  setCurrentOrgId,
}) {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    resetForm();
    setModalIsOpen(false);
  };

  const handleUpdateClickWithModal = (id) => {
    const orgToEdit = org.find((org) => org.id === id);
    if (orgToEdit) {
      setForm(orgToEdit);
      setIsUpdating(true);
      setCurrentOrgId(id);
      openModal();
    }
  };

  const handleNewClick = () => {
    resetForm();
    openModal();
  };

  const resetForm = () => {
    setForm({
      name: "",
      address: "",
      type: "private",
      externalId: "",
      walletAddress: "",
      web3Id: "",
      emailId: "",
      status: "pending",
    });
    setIsUpdating(false);
    setCurrentOrgId(null); // Reset currentOrgId when creating a new organization
  };

  return (
    <>
      <div className={styles.organizationContainer}>
        <PageHeader
          pageName={"Organization"}
          actions={
            <button className={styles.button} onClick={handleNewClick}>
              New
            </button>
          }
        />
        <OrganizationTable
          org={org}
          handleUpdateClick={handleUpdateClickWithModal}
          handleDeleteClick={handleDeleteClick}
        />
      </div>
      {modalIsOpen && (
        <Modal show={modalIsOpen} onHide={closeModal} className={styles.modal}>
          <Modal.Header bsPrefix={cn(styles.modalHeader, 'modal-header')}>
            {isUpdating ? 'Edit Organization' : 'Add Organization' }
          </Modal.Header>
          <Modal.Body>
            <OrganizationForm
              form={form}
              handleChange={handleChange}
              onCancel={closeModal}
              handleSubmit={(e) => {
                handleSubmit(e);
                closeModal();
              }}
              isUpdating={isUpdating}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default AdminPanel;
