export const ReportsIcon = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2_199)">
        <path
          d="M20.1196 3H19.1058V1H17.0782V3H8.96756V1H6.93991V3H5.92609C4.81088 3 3.89844 3.9 3.89844 5V19C3.89844 20.1 4.81088 21 5.92609 21H20.1196C21.2348 21 22.1473 20.1 22.1473 19V5C22.1473 3.9 21.2348 3 20.1196 3ZM20.1196 19H5.92609V9H20.1196V19ZM5.92609 7V5H20.1196V7H5.92609ZM11.563 17.46L17.5749 11.53L16.5003 10.47L11.563 15.34L9.42378 13.23L8.34913 14.29L11.563 17.46Z"
          fill="white"
          fill-opacity="0.8"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_199">
          <rect
            width="24.3318"
            height="24"
            fill="white"
            transform="translate(0.856934)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
