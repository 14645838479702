export const DashboardIcon = () => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2_177)">
        <path
          d="M20.1196 5V7H16.0643V5H20.1196ZM9.98139 5V11H5.92609V5H9.98139ZM20.1196 13V19H16.0643V13H20.1196ZM9.98139 17V19H5.92609V17H9.98139ZM22.1473 3H14.0367V9H22.1473V3ZM12.009 3H3.89844V13H12.009V3ZM22.1473 11H14.0367V21H22.1473V11ZM12.009 15H3.89844V21H12.009V15Z"
          fill="white"
          fill-opacity="0.84"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_177">
          <rect
            width="24.3318"
            height="24"
            fill="white"
            transform="translate(0.856934)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
