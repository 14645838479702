import React from 'react';
import Modal from 'react-modal';
import styles from './UserInfoModal.module.css';

Modal.setAppElement('#root');

function UserInfoModal({ isOpen, toggle, userInfo }) {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={toggle}
      contentLabel="User Info Modal"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2>User Information</h2>
      <div className={styles.userInfo}>
        <p><strong>Name:</strong> {userInfo?.name}</p>
        <p><strong>Email ID:</strong> {userInfo?.email}</p>
        <p><strong>Wallet Address:</strong> {userInfo?.publicAddress}</p>
        <p><strong>Location:</strong> {userInfo?.location}</p>
      </div>
      <button className={styles.closeButton} onClick={toggle}>Close</button>
    </Modal>
  );
}

export default UserInfoModal;
