import React from 'react';
import './NoteDetails.css';

const NoteDetails = ({ selectedNote }) => {
  const { metadata } = selectedNote;
  const formattedAccessionNo = metadata.report.accessionNumber.replace(/-/g, '');
  
  const link = `https://www.sec.gov/Archives/edgar/data/${metadata.report.depositor.depositorCIK}/${formattedAccessionNo}/${metadata.report.primaryDocument}`;
  const wallet = metadata.holder.organization.walletAddress?`${metadata.holder.organization.walletAddress.slice(0, 8)}...${metadata.holder.organization.walletAddress.slice(-8)}`: ""
  return (
    <div className="note-details-container">
      <div className="note-section">
        <h2>Note Details</h2>
        <p><strong>Loan ID:</strong> {metadata.loanParentId}</p>
        <p><strong>Loan Name:</strong> {metadata.loan.loan}</p>
        <p><strong>Note ID:</strong> {metadata.id}</p>
        <p><strong>Note Name:</strong> {metadata.noteName}</p>
        <p><strong>Control:</strong> {metadata.control}</p>
        <p><strong>Balance:</strong> ${metadata.balance}</p>
        <p><strong>Balance Description:</strong> {metadata.balanceDescription}</p>
        <p><strong>Holder ID:</strong> {metadata.holderParentId}</p>
        <p><strong>Created At:</strong> {new Date(metadata.createdAt).toLocaleDateString()}</p>
        <p><strong>Updated At:</strong> {new Date(metadata.updatedAt).toLocaleDateString()}</p>
      </div>

      <div className="report-section">
        <h3>Report Details</h3>
        <p><strong>Deal:</strong> {metadata.report.deal.deal}</p>
        <p><strong>Accession Number:</strong> {metadata.report.accessionNumber}</p>
        <p><strong>Primary Document:</strong> <a href={link} target="_blank" rel="noopener noreferrer">{metadata.report.primaryDocument}</a></p>
        <p><strong>Filing Date:</strong> {new Date(metadata.report.filingDate).toLocaleDateString()}</p>
        <p><strong>Document Date:</strong> {new Date(metadata.report.documentDate).toLocaleDateString()}</p>
        <p><strong>Closing Date:</strong> {new Date(metadata.report.closingDate).toLocaleDateString()}</p>
      </div>

      <div className="holder-section">
        <h3>Holder Details</h3>
        <p><strong>Holder Name:</strong> {metadata.holder.holderName}</p>
        <p><strong>Organization Name:</strong> {metadata.holder.organization.name}</p>
        <p><strong>Organization Address:</strong> {metadata.holder.organization.address}</p>
        <p><strong>Wallet Address:</strong>{wallet}</p>
      </div>
    </div>
  );
};

export default NoteDetails;