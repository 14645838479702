import React from "react";
import cn from 'classnames';
import styles from "./Sidebar.module.css";
import { DashboardIcon } from "./icons/DashboardIcon";
import { NotesIcon } from "./icons/NotesIcon";
import { OrganizationIcon } from "./icons/OrganizationIcon";
import { ReportsIcon } from "./icons/ReportsIcon";
import { SettingsIcon } from "./icons/SettingsIcon";

function Sidebar({ setActivePage, activePage, email }) {
  const role = localStorage.getItem('role')
  // (role === "admin"? setActivePage(localStorage.getItem('activePage')): setActivePage("notes"))
  return (
    <div className={styles.sidebar}>
      <h2 className={styles.sidebarHeader}>
        <img
          className={styles.sidebarHeaderImage}
          src={process.env.PUBLIC_URL + "/logo_white.png"}
          alt="Logo"
          width={"100%"}
          height={"70%"}
        />
      </h2>
      { role === "admin"?(
      <ul className={styles.menu}>
        <li className={styles.menuItem}>
          <button
            className={cn(styles.menuItemLink, {[styles.isActive]: activePage === 'dashboard'})}
            onClick={() => setActivePage("dashboard")}
          >
            <DashboardIcon />
            Dashboard
          </button>
        </li>
        <li className={styles.menuItem}>
          <button
            className={cn(styles.menuItemLink, {[styles.isActive]: activePage === 'notes'})}
            onClick={() => setActivePage("notes")}
          >
            <NotesIcon /> Notes
          </button>
        </li>
        <li className={styles.menuItem}>
          <button
            className={cn(styles.menuItemLink, {[styles.isActive]: activePage === 'organization'})}
            onClick={() => setActivePage("organization")}
          >
            <OrganizationIcon /> Organization
          </button>
        </li>

        <li className={styles.menuItem}>
          <button
            className={cn(styles.menuItemLink, {[styles.isActive]: activePage === 'reports'})}
            onClick={() => setActivePage("reports")}
          >
            <ReportsIcon /> Reports
          </button>
        </li>
        <li className={styles.menuItem}>
          <button
            className={cn(styles.menuItemLink, {[styles.isActive]: activePage === 'settings'})}
            onClick={() => setActivePage("settings")}
          >
            <SettingsIcon /> Settings
          </button>
        </li>
      </ul>):(
              <ul className={styles.menu}>
                <li className={styles.menuItem}>
                  <button
                    className={cn(styles.menuItemLink, {[styles.isActive]: activePage === 'notes'})}
                    onClick={() => setActivePage("notes")}
                  >
                    <NotesIcon /> My Notes
                  </button>
                </li>
              </ul>
      )}
    </div>
  );
}

export default Sidebar;
