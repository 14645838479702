import React, { useState } from 'react';

const LoanList = ({ loanData, handleLoanSelection }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calculate total pages
  const totalPages = Math.ceil(loanData.length / itemsPerPage);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loanData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="loan-container">
      <table className="loan-table">
        <thead>
          <tr>
            <th>Loan ID</th>
            <th>Loan Name</th>
            <th>Parent Loan Name</th>
            <th>Transaction Hash</th>
            <th>Created Date</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map(({ loanId, metadata }, index) => (
            <tr key={index} onClick={() => handleLoanSelection(loanId)}>
              <td>{loanId}</td>
              <td>{metadata?.loanName || 'N/A'}</td>
              <td>{metadata?.loanParent || 'N/A'}</td>
              <td
                onClick={(e) => {
                  e.stopPropagation();
                  if (metadata?.loanTxHash) {
                    window.open(`${process.env.REACT_APP_EXP_URL}${metadata.loanTxHash}`, '_blank');
                  }
                }}
                style={{ cursor: metadata?.loanTxHash ? 'pointer' : 'default', 'color':'blue' }}
              >
                  {metadata?.loanTxHash
                    ? `${metadata.loanTxHash.slice(0, 8)}...${metadata.loanTxHash.slice(-8)}`
                    : 'N/A'}
              </td>
              <td>{metadata?.createdAt || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="pagination">
        {/* Previous Page Button */}
        <button 
          className="arrow-button"
          onClick={() => handlePageChange(currentPage - 1)} 
          disabled={currentPage === 1}
        >
          &larr;
        </button>

        {/* Page Numbers */}
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))}

        {/* Next Page Button */}
        <button 
          className="arrow-button"
          onClick={() => handlePageChange(currentPage + 1)} 
          disabled={currentPage === totalPages}
        >
          &rarr;
        </button>
      </div>
    </div>
  );
};

export default LoanList;
