import React from 'react';
import styles from './Header.module.css';
import { shortenAddress } from './utils';

function Header({ logout, userInfo, toggleUserInfoModal }) {
  return (
    <header className={styles.header}>
      <div className={styles.userInfoWihLogout}>
        <span
          className={styles.userWalletAddress}
          onClick={toggleUserInfoModal}
        >
          {shortenAddress(userInfo?.publicAddress)}
        </span>
        <button className={styles.logoutButton} onClick={logout}>Logout</button>
      </div>
      
    </header>
  );
}

export default Header;