export const NotesIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2628 14V6C19.2628 4.9 18.3503 4 17.2351 4H3.04157C1.92636 4 1.01392 4.9 1.01392 6V14C1.01392 15.1 1.92636 16 3.04157 16H17.2351C18.3503 16 19.2628 15.1 19.2628 14ZM17.2351 14H3.04157V6H17.2351V14ZM10.1383 7C8.45539 7 7.09687 8.34 7.09687 10C7.09687 11.66 8.45539 13 10.1383 13C11.8213 13 13.1798 11.66 13.1798 10C13.1798 8.34 11.8213 7 10.1383 7ZM23.3181 7V18C23.3181 19.1 22.4056 20 21.2904 20H4.05539C4.05539 19 4.05539 19.1 4.05539 18H21.2904V7C22.4056 7 22.3042 7 23.3181 7Z"
        fill="white"
        fill-opacity="0.8"
      />
    </svg>
  );
};
