import React, { useState } from "react";
import cn from 'classnames';
import styles from "./OrganizationTable.module.css";
import { Table, Dropdown } from "react-bootstrap";

function OrganizationTable({ org, handleUpdateClick, handleDeleteClick }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const itemsPerPage = 12;

  // Calculate total pages
  const totalPages = Math.ceil(org.length / itemsPerPage);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Filter organizations based on search query
  const filteredOrg = org.filter(item =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  const currentItems = filteredOrg.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when searching
  };

  const ActionMenu = ({ orgId }) => {
    return (
      <Dropdown className={styles.actionMenuContainer}>
        <Dropdown.Toggle
          variant="link"
          id="dropdown-basic"
          className={styles.dropdownToggleButton}
        >
          <i className="bi bi-three-dots-vertical"></i>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item href="#/edit">
            <button className={styles.button} onClick={() => handleUpdateClick(orgId)}>
              <i className="bi bi-pencil"></i> Update
            </button>
          </Dropdown.Item>
          <Dropdown.Item href="#/delete">
            <button className={styles.button} onClick={() => handleDeleteClick(orgId)}>
              <i className="bi bi-x-circle-fill"></i> Delete
            </button>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <div className={styles.tableOuterContainer}>
      <div className={styles.filterContainer}>
        <div className={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Search"
            value={searchQuery}
            onChange={handleSearchChange}
            className={styles.searchInput}
          />
        </div>
      </div>
      <div className={styles.tableContainer}>
        <Table hover striped={false} bordered={false} className={styles.table}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email ID</th>
              <th>Type</th>
              <th>Wallet Address</th>
              <th>Status</th>
              <th className={styles.actionCell}>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.length > 0 ? (
              currentItems.map((orgs) => (
                <tr key={orgs.id}>
                  <td>
                    <span className={styles.orgNameCell}>{orgs.name}</span>
                  </td>
                  <td>
                    <span className={styles.emailIdCell}>{orgs.emailId}</span>
                  </td>
                  <td>{orgs.type}</td>
                  <td>
                    <span className={styles.walletAddressCell}>
                      {orgs.walletAddress}
                    </span>
                  </td>
                  <td>
                    <span className={cn(styles.status, { [styles.active]: orgs.status?.toLowerCase() === 'active', [styles.pending]: orgs.status?.toLowerCase() === 'pending' })}>
                      {orgs.status}
                    </span>
                  </td>
                  <td className={styles.actionCell}>
                    <ActionMenu orgId={orgs.id} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td style={{ textAlign: "center" }} colSpan={6}>
                  <span>No Records Found</span>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <div className={styles.pagination}>
        {/* Previous Page Button */}
        <button
          className={styles.arrowButton}
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &larr;
        </button>

        {Array.from({ length: Math.ceil(filteredOrg.length / itemsPerPage) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={cn(styles.pageButton, { [styles.active]: currentPage === index + 1 })}
          >
            {index + 1}
          </button>
        ))}

        <button
          className={styles.arrowButton}
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredOrg.length / itemsPerPage)}
        >
          &rarr;
        </button>
      </div>
    </div>
  );
}

export default OrganizationTable;