import "./App.css";
import { useState, useEffect } from "react";
import axios from "axios";
import { PageHeader } from "./PageHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Magic } from "magic-sdk";
import LoginForm from "./LoginForm";
import AdminPanel from "./AdminPanel";
import { ethers } from "ethers";
import Sidebar from "./Sidebar";
import Header from "./Header";
import OTPModal from "./OTPModal";
import UserInfoModal from "./UserInfoModal";
import { jwtDecode } from "jwt-decode";
import LoanList from "./LoanList";
import NoteList from "./NoteList";
import NoteDetails from "./NoteDetails";
import HistoryList from "./HistoryList";
import ButtonGroup from "./ButtonGroup";
import Loader from "./Loader";

const PORT = process.env.REACT_APP_PORT;

const magic = new Magic(process.env.REACT_APP_API_KEY,{
  network: {
    rpcUrl: process.env.REACT_APP_RPC_URL,
  }
});

// const magic = new Magic(process.env.REACT_APP_API_KEY, {
//   network: {
//     chainId: 11155111,
//     rpcUrl: process.env.REACT_APP_RPC_URL,
//   },
// });

function App() {
  const [org, setOrg] = useState([]);
  const [form, setForm] = useState({
    id: null,
    name: "",
    address: "",
    type: "private",
    externalId: "",
    walletAddress: "",
    web3Id: "",
    emailId: "",
    status: "pending",
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [currentOrgId, setCurrentOrgId] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn")
  );
  const [userInfo, setUserInfo] = useState(null);
  const [email, setEmail] = useState(localStorage.getItem("email"));
  // const [externalId, setExternalId] = useState(localStorage.getItem('externalId'));
  const [activePage, setActivePage] = useState(
    localStorage.getItem("activePage")
  );
  const [isUserInfoModalOpen, setIsUserInfoModalOpen] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [otpLogin, setOtpLogin] = useState(null);
  const [loanData, setLoanData] = useState([]);
  const [noteData, setNoteData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [showLoanTable, setShowLoanTable] = useState(true);
  const [showNoteTable, setShowNoteTable] = useState(true);
  const [showHistoryTable, setShowHistoryTable] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [historyData, setHistoryData] = useState([]);
  const [isLoadingLoans, setIsLoadingLoans] = useState(true); 
  const [isLoadingNotes, setIsLoadingNotes] = useState(false);

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const status = await magic.user.isLoggedIn();
        const role = localStorage.getItem("role");

        if (status) {
          setIsLoggedIn(true);
          const userInfo = await magic.user.getInfo();
          setUserInfo(userInfo);

          if (role === "admin") {
            fetchOrganizations();
            fetchLoans();
          } else {
            await checkAndFetchUserInformation(userInfo.email);
          }
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error("Error checking user status:", error);
      }
    };

    checkUserStatus();
  }, []);

  const login = async (emailAddress) => {
    try {
      setOtpLogin();
      const otpLogin = magic.auth.loginWithEmailOTP({
        email: emailAddress,
        showUI: false,
        deviceCheckUI: false,
      });
      setOtpLogin(otpLogin);

      otpLogin
        .on("device-needs-approval", () => {
          console.log("device-needs-approval");
        })
        .on("device-approved", () => {
          console.log("device-approved");
        })
        .on("email-otp-sent", () => {
          setShowOTPModal(true);
        })
        .on("done", async (result) => {
          try {
            const did = result;
            const response = await axios.post(
              `${PORT}login`,
              {},
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: did,
                },
              }
            );

            if (response.data.token) {
              const decodedToken = jwtDecode(response.data.token);
              const role = decodedToken.role;
              localStorage.setItem("token", response.data.token);
              localStorage.setItem("role", role);
              const userInfo = await magic.user.getInfo();

              setIsLoggedIn(true);
              localStorage.setItem("isLoggedIn", true);
              setUserInfo(userInfo);
              if (role === "admin") {
                const response = await axios.get(`${PORT}getUserInformation/${emailAddress}`);

                if(response.data.walletAddress === null && response.data.status === 'pending'){
                  setCurrentOrgId(response.data.id);
                  
                  const formToSubmit = {
                    ...response.data,
                    walletAddress: userInfo.publicAddress,
                    emailId: userInfo.email,
                    status: "active",
                  };
  
                  await axios.put(
                    `${PORT}updateOrganization/${response.data.id}`,
                    formToSubmit,
                    {
                      headers: {
                        "Content-Type": "application/json",
                      },
                    }
                  );
                }

                fetchOrganizations();
                fetchLoans();
                setActivePage("dashboard");
                localStorage.setItem("activePage", "dashboard");
                toast.success("Logged in successfully as admin");
              } else {
                checkAndFetchUserInformation(userInfo.email);
                setActivePage("notes");
                localStorage.setItem("activePage", "notes");
                toast.success("Logged in successfully as user");
              }

              localStorage.setItem("email", emailAddress);
            }
          } catch (error) {
            if (error.code === "ERR_BAD_REQUEST") {
              toast.error("Access denied! User not invited");
              console.error("Access denied! User not invited", error);
            } else {
              console.error("Login failed:", error);
            }
          } finally {
            setShowOTPModal(false);
            setOtpLogin(null);
          }
        })
        .on("settled", () => {
          setShowOTPModal(false);
          setOtpLogin(null);
        })
        .catch((err) => {
          console.error(err);
          setShowOTPModal(false);
          setOtpLogin(null);
        });
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        toast.error("Access denied! User not invited");
        console.error("Access denied! User not invited", error);
      } else {
        console.error("Login failed:", error);
      }
    }
  };

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    login(email);
  };

  const handleCancelOTP = () => {
    try {
      otpLogin.emit("cancel");
      setShowOTPModal(false);
      setOtpLogin(null);
    } catch (err) {
      console.error("Error canceling OTP login:", err);
    }
  };

  const logout = async () => {
    try {
      await magic.user.logout();
      setIsLoggedIn(false);
      localStorage.setItem("isLoggedIn", false);
      localStorage.removeItem("token");
      localStorage.removeItem("notes");
      removeLoanSelection();
      setUserInfo(null);
      setOrg([]);
      resetForm();
      // localStorage.removeItem('externalId')
      localStorage.removeItem("email");
      localStorage.removeItem("activePage");
      localStorage.removeItem("role");
      setHistoryData([]);
      setSelectedNote(null);
      setUserData([]);
      setLoanData([]);
      setNoteData([]);
      toast.success("Logged out successfully");
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("Failed to log out");
    }
  };

  const fetchOrganizations = async () => {
    try {
      const response = await axios.get(`${PORT}getOrganizations`);
      const sortedData = response.data.sort((a, b) => a.id - b.id);
      setOrg(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchLoans = async () => {
    try {
      setIsLoadingLoans(true); 
      const response = await axios.get(`${PORT}getAllLoans`);
      setLoanData(response.data);
    } catch (error) {
      console.error("Error fetching loans:", error);
    } finally {
      setIsLoadingLoans(false); 
    }
  };

  const fetchMyLoans = async (loanIds) => {
    try {
      const response = await axios.post(`${PORT}getMyLoans`, { loanIds });
      setLoanData(response.data);
    } catch (error) {
      console.error("Error fetching my loans:", error);
    }
  };

  const fetchMyNotes = async (loanId, userData) => {
    try {
      const response = await axios.post(`${PORT}getMyNotes`, { loanId, userData });
      setNoteData(response.data);
    } catch (error) {
      console.error("Error fetching my notes:", error);
    }
  };

  const showHistory = async (selectedNote) => {
    try {
      const response = await axios.post(`${PORT}getNoteHistory`, { selectedNote });
      setShowHistoryTable(true);
      setHistoryData(response.data);
    } catch (error) {
      console.error("Error fetching note history:", error);
    }
  };

  const handleLoanSelection = (loanId) => {
    if (role === "admin") {
      fetchNotes(loanId);
    } else {
      fetchMyNotes(loanId, userData);
    }
    setShowNoteTable(true);
    setShowLoanTable(false);
  };

  const removeLoanSelection = () => {
    setShowLoanTable(true);
    setShowNoteTable(false);
    setNoteData([]);
  };

  const removeNoteSelection = () => {
    setSelectedNote(null);
    setShowLoanTable(false);
    setShowNoteTable(true);
  };

  const handleNoteSelection = (noteId) => {
    setSelectedNote(noteId);
    setShowNoteTable(false);
  };

  const removeHistorySelection = () => {
    setShowHistoryTable(false);
    setHistoryData([]);
  };

  const fetchNotes = async (loanId) => {
    try {
      setIsLoadingNotes(true); 
      const response = await axios.post(`${PORT}getAllNotes`, { loanId });
      setNoteData(response.data);
    } catch (error) {
      console.error("Error fetching notes:", error);
    } finally {
      setIsLoadingNotes(false); 
    }
  };

  const fetchOwnerDetails = async () => {
    try {
      const userInfo = await magic.user.getInfo();
      const address = userInfo?.publicAddress
      const result = await axios.post(`${PORT}getOwnerLoanAndNotes`, { address });
      const notes = result.data
      
      const formattedResult = notes.map((item) => {
        if (Array.isArray(item)) {
          return item.map((subItem) => {
            if (subItem._hex) {
              return ethers.BigNumber.from(subItem).toNumber();
            }
            return subItem;
          });
        } else if (item._hex) {
          return ethers.BigNumber.from(item).toNumber();
        }
        return item;
      });

      const sortedData = formattedResult.sort((a, b) => a.id - b.id);
      setUserData(sortedData);
      fetchMyLoans([...new Set(sortedData.map((item) => item[0]))]);
    } catch (error) {
      console.error("Error fetching owner details", error);
    }
  };

  const checkAndFetchUserInformation = async (email) => {
    try {
      const [userInfo, response] = await Promise.all([
        magic.user.getInfo(),
        axios.get(`${PORT}getUserInformation/${email}`),
      ]);
  
      if (!response.data) {
        throw new Error("No user information found");
      }
  
      setCurrentOrgId(response.data.id);
  
      const formToSubmit = {
        ...response.data,
        walletAddress: userInfo.publicAddress,
        emailId: userInfo.email,
        status: "active",
      };
  
      fetchOwnerDetails();
      setForm(formToSubmit);
      setIsUpdating(true);
  
      // Only update if status is not "active"
      if (response.data.status !== "active") {
        await Promise.all([
          axios.put(
            `${PORT}updateOrganization/${formToSubmit.id}`,
            formToSubmit,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ),
          axios.post(
            `${PORT}noteTransfer`,
            formToSubmit,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
        ]);
      }
    } catch (error) {
      console.error(error);
  
      const userInfo = await magic.user.getInfo();
  
      if (error.message === "No user information found") {
        toast.error("Request admin to send an email invite");
      } else {
        toast.error("Failed to fetch user information");
      }
  
      setForm({
        id: null,
        name: "",
        address: "",
        type: "private",
        externalId: "",
        walletAddress: userInfo.publicAddress,
        web3Id: "",
        emailId: userInfo.email,
        status: "pending",
      });
    }
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formToSubmit = { ...form };
      const role = localStorage.getItem("role");
      if (role === "admin") {
        formToSubmit.status = "pending";
      } else {
        formToSubmit.status = "active";
      }

      if (role === "admin") {
        if (isUpdating) {
          await axios.put(
            `${PORT}updateOrganization/${currentOrgId}`,
            formToSubmit,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          fetchOrganizations();
          toast.success("Organization updated successfully");
        } else {
          const response = await axios.post(
            `${PORT}createOrganization`,
            formToSubmit,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setOrg([...org, response.data]);
          fetchOrganizations();
          toast.success("Organization added successfully");
          resetForm();
        }
      } else {
        if (currentOrgId != null) {
          await axios.put(
            `${PORT}updateOrganization/${currentOrgId}`,
            formToSubmit,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          toast.success("Information updated successfully");
        } else {
          const response = await axios.post(
            `${PORT}createOrganization`,
            formToSubmit,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          setOrg([...org, response.data]);
          toast.success("Organization added successfully");
          resetForm();
        }
      }
    } catch (error) {
      console.error(error);
      toast.error(
        isUpdating
          ? "Failed to update organization"
          : "Failed to add organization"
      );
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${PORT}deleteOrganization/${id}`);
      fetchOrganizations();
      toast.success("Organization deleted successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to delete organization");
    }
  };

  const resetForm = () => {
    setForm({
      id: null,
      name: "",
      address: "",
      type: "private",
      externalId: "",
      walletAddress: "",
      web3Id: "",
      emailId: "",
      status: "pending",
    });
    setIsUpdating(false);
    setCurrentOrgId(null);
  };

  const toggleUserInfoModal = () => {
    setIsUserInfoModalOpen(!isUserInfoModalOpen);
  };
  const role = localStorage.getItem("role");
  return (
    <div className="App">
      {isLoggedIn && activePage ? (
        <>
          <Header
            logout={logout}
            userInfo={userInfo}
            toggleUserInfoModal={toggleUserInfoModal}
          />
          <Sidebar
            activePage={activePage}
            email={email}
            setActivePage={setActivePage}
            userInfo={userInfo}
          />
          <div className="main-content">
            {activePage === "organization" ? (
              <AdminPanel
                org={org}
                form={form}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isUpdating={isUpdating}
                setIsUpdating={setIsUpdating}
                setForm={setForm}
                handleDeleteClick={handleDeleteClick}
                logout={logout}
                setCurrentOrgId={setCurrentOrgId}
              />
            ) : activePage === "notes" ? (
              <div>
                <PageHeader pageName={activePage} />
                <div className="main-page-content">
                {isLoadingLoans ? (
                    <Loader /> 
                  ) : loanData.length === 0 ? (
                    <div>Currently there are no active Notes</div>
                  ) : (
                    <div>
                      {showLoanTable ? (
                        <LoanList
                          loanData={loanData}
                          handleLoanSelection={handleLoanSelection}
                        />
                      ) : isLoadingNotes ? (
                        <Loader /> 
                      ) :!showNoteTable ? (
                        showHistoryTable ? (
                          <HistoryList historyData={historyData} />
                        ) : (
                          <NoteDetails selectedNote={selectedNote} />
                        )
                      ) : (
                        <NoteList
                          noteData={noteData}
                          handleNoteSelection={handleNoteSelection}
                        />
                      )}
                      {!showLoanTable ? (
                        <ButtonGroup
                          showNoteTable={showNoteTable}
                          showHistoryTable={showHistoryTable}
                          removeLoanSelection={removeLoanSelection}
                          removeNoteSelection={removeNoteSelection}
                          removeHistorySelection={removeHistorySelection}
                          showHistory={showHistory}
                          showLoanTable={showLoanTable}
                          selectedNote={selectedNote}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <PageHeader pageName={activePage} />
                <div className="main-page-content">Welcome to {activePage}</div>
              </div>
            )}
          </div>
        </>
      ) : (
        <LoginForm
          email={email}
          handleLoginSubmit={handleLoginSubmit}
          setEmail={setEmail}
        />
      )}
      <UserInfoModal
        isOpen={isUserInfoModalOpen}
        toggle={toggleUserInfoModal}
        userInfo={userInfo}
      />
      {showOTPModal && (
        <OTPModal login={otpLogin} handleCancel={handleCancelOTP} />
      )}
      <ToastContainer />
    </div>
  );
}

export default App;
