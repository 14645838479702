import React from 'react';
import styles from "./Sidebar.module.css";

function LoginForm({ email, handleLoginSubmit, setEmail }) {
  return (
    <div className="login-container">
      <form onSubmit={handleLoginSubmit} className="login-form">
        <div className="form-group">
          <h2 className={styles.sidebarHeader}>
        <img
          className={styles.sidebarHeaderImage}
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="Logo"
          width={"80%"}
          height={"100%"}
        />
      </h2>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login / Sign Up</button>
        {/* <p className='login-form-text'>OR</p> */}
        <div className='terms'><p>Privacy</p><p>&#8226;</p><p>Terms</p></div>
      </form>
    </div>
  );
}

export default LoginForm;