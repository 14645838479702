export const OrganizationIcon = () => {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.9005 9.13C17.2895 10.06 18.2627 11.32 18.2627 13V16H22.318V13C22.318 10.82 18.6987 9.53 15.9005 9.13Z"
        fill="white"
      />
      <path
        d="M14.2074 8C16.4479 8 18.2627 6.21 18.2627 4C18.2627 1.79 16.4479 0 14.2074 0C13.7309 0 13.2848 0.0999998 12.859 0.24C13.7005 1.27 14.2074 2.58 14.2074 4C14.2074 5.42 13.7005 6.73 12.859 7.76C13.2848 7.9 13.7309 8 14.2074 8Z"
        fill="white"
      />
      <path
        d="M8.12445 8C10.365 8 12.1798 6.21 12.1798 4C12.1798 1.79 10.365 0 8.12445 0C5.8839 0 4.06915 1.79 4.06915 4C4.06915 6.21 5.8839 8 8.12445 8ZM8.12445 2C9.23966 2 10.1521 2.9 10.1521 4C10.1521 5.1 9.23966 6 8.12445 6C7.00924 6 6.0968 5.1 6.0968 4C6.0968 2.9 7.00924 2 8.12445 2Z"
        fill="white"
      />
      <path
        d="M8.12445 9C5.41754 9 0.013855 10.34 0.013855 13V16H16.2351V13C16.2351 10.34 10.8314 9 8.12445 9ZM14.2074 14H2.0415V13.01C2.24427 12.29 5.38713 11 8.12445 11C10.8618 11 14.0046 12.29 14.2074 13V14Z"
        fill="white"
      />
    </svg>
  );
};
