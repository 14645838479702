import React from 'react';
import './ButtonGroup.css';

const ButtonGroup = ({
  showNoteTable,
  showHistoryTable,
  removeLoanSelection,
  removeNoteSelection,
  removeHistorySelection,
  showHistory,
  showLoanTable,
  selectedNote
}) => {
  return (
    <div className="button-group">
      {showNoteTable ? (
        <button className="btn" onClick={removeLoanSelection}>Back to Loan List</button>
      ) : (
        !showHistoryTable ? (
          <>
            <button className="btn" onClick={removeNoteSelection}>Back to Note List</button>
            <button className="btn" onClick={() => showHistory(selectedNote)}>Show Note History</button>
          </>
        ) : (
          <button className="btn" onClick={removeHistorySelection}>Back to Selected Note</button>
        )
      )}
    </div>
  );
};

export default ButtonGroup;