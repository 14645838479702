import React from "react";
import styles from "./OrganizationForm.module.scss";
import cn from 'classnames';

function OrganizationForm({
  form,
  handleChange,
  handleSubmit,
  isUpdating,
  onCancel,
}) {
  return (
    <form onSubmit={handleSubmit} className={styles.organizationFormContainer}>
      <div className={styles.organizationForm}>
        {["name", "address", "externalId", "emailId"].map((key) => (
          <div key={key} className={styles.formGroup}>
            <label htmlFor={key}>{key}</label>
            <input
              type="text"
              id={key}
              name={key}
              value={form[key]}
              onChange={handleChange}
              required
            />
          </div>
        ))}
        <div className={styles.formGroup}>
          <label htmlFor="type">Type</label>
          <select
            id="type"
            name="type"
            value={form.type}
            onChange={handleChange}
            required
          >
            <option value="private">Private</option>
            <option value="public">Public</option>
            <option value="depositor">Depositor</option>
          </select>
        </div>
      </div>
      <div className={styles.formActions}>
        <button onClick={onCancel} className={cn(styles.cancelButton, styles.button)}>Cancel</button>
        <button type="submit" className={cn(styles.submitButton, styles.button)}>
          Save
        </button>
      </div>
    </form>
  );
}

export default OrganizationForm;
