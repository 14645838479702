import React, { useState } from 'react';

const NoteList = ({ noteData, handleNoteSelection }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Calculate total pages
  const totalPages = Math.ceil(noteData.length / itemsPerPage);

  // Get current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = noteData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="note-container">
      <table className="note-table">
        <thead>
          <tr>
            <th>Loan Name</th>
            <th>Note Id</th>
            <th>Note Name</th>
            <th>Balance</th>
            <th>Transaction Hash</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            currentItems.map((note, index) => (
              <tr key={index} onClick={() => handleNoteSelection(note)}>
                <td>{note.metadata.loan.loan}</td>
                <td>{note.metadata.id}</td>
                <td>{note.metadata.noteName}</td>
                <td>{note.metadata.balance ?? 'N/A'}</td>
                <td
                  onClick={(e) => {
                    e.stopPropagation();
                    if (note.metadata.noteTxHash) {
                      window.open(`${process.env.REACT_APP_EXP_URL}${note.metadata.noteTxHash}`, '_blank');
                    }
                  }}
                  style={{ cursor: note.metadata.noteTxHash ? 'pointer' : 'default', 'color':'blue' }}
                >
                  {note.metadata.noteTxHash
                    ? `${note.metadata.noteTxHash.slice(0, 8)}...${note.metadata.noteTxHash.slice(-8)}`
                    : 'N/A'}
                </td>
                <td>{note.metadata.createdAt ? new Date(note.metadata.createdAt).toLocaleDateString() : 'N/A'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">No notes available</td>
            </tr>
          )}
        </tbody>
      </table>

      <div className="pagination">
        {/* Previous Page Button */}
        <button
          className="arrow-button"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &larr;
        </button>

        {/* Page Numbers */}
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))}

        {/* Next Page Button */}
        <button
          className="arrow-button"
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          &rarr;
        </button>
      </div>
    </div>
  );
};

export default NoteList;